<template>
    <div class="d-flex content-center min-vh-100">
        <BContainer>
            <BRow class="justify-content-center">
                <BCol md="8">
                    <BCard class="text-left"  footer-tag="footer" footer-bg-variant="light">
                        <div slot="header">
                            <strong>제품 주문서</strong>
                            <div class="card-header-actions">
                                <small class="text-muted">제품을 주문합니다</small>
                            </div>
                        </div>


                        <div slot="footer">
                            <div class="small text-muted">
                                <strong><i class="fa fa-copyright"></i> <a href='http://qqkorea.net'>(주)하우코스트</a></strong>
                                <span class="hidden-xs en">All rights reserved.</span><br>
                                <span class="hidden-xs en">
                                    대표자 : 유현오 | E-Mail : <a href="mailto:estimate@howcost.co.kr">estimate@howcost.co.kr</a>) <br>
                                    <a href='http://xcost.me/bbs/qalist.php'>적산문의/프로그램/문의 1:1상담</a> | tel. 031-417-0466 <br/>
                                    사무실 : 경기도 수원시 권선구 수성로 8 경기중소기업성장지원센터 303호 <br/>부설연구소 : 건설계약연구소/벤쳐기업인증/연구개발서비스업등록/소프트웨어개발업/ISO9001,ISO14001인증<br/>
                                    사업자번호 : 809-86-00250</span>
                            </div>
                        </div>

                        <BCard v-if="prdInfo">
                            <div slot="header">
                                <strong>{{prdInfo.disp_name}}</strong>
                            </div>
                            <strong v-html="prdInfo.description"></strong>

                            <BImg :src="imgSrc" fluid-grow/>


                        </BCard>

                        <BRow>
                            <BCol v-if="orderEnabled">
                                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                                    <BForm ref="orderFrom" @submit.stop.prevent="handleSubmit(onSubmit)">
                                        <validation-provider
                                                name="email"
                                                rules="required|email"
                                                v-slot="validationContext">
                                            <BInputGroup  class="mb-3" id="bigEmail">
                                                <BInputGroupPrepend is-text>
                                                    <BIconAt/>
                                                </BInputGroupPrepend>
                                                <BFormInput
                                                        v-model="order.email"
                                                        type="email"
                                                        :state="getValidationState(validationContext)"
                                                        placeholder="이메일 주소"
                                                        autocomplete="email"
                                                        aria-describedby="email-feedback"
                                                        autofocus
                                                        :disabled="isEmailChecked"/>
                                                <BInputGroupAppend>
                                                    <BButton
                                                            variant="warning"
                                                            @click="sendAuthEmail"
                                                            :disabled="!getValidationState(validationContext) || isEmailChecked">
                                                        <BSpinner small v-show="isEmailSending"></BSpinner>
                                                        인증번호 전송
                                                    </BButton>
                                                </BInputGroupAppend>
                                                <BFormInvalidFeedback id="email-feedback" class="text-left">
                                                    {{ validationContext.errors[0] }}
                                                </BFormInvalidFeedback>
                                                <BFormValidFeedback>{{emailValidCheckText}}</BFormValidFeedback>
                                            </BInputGroup>
                                        </validation-provider>

                                        <validation-provider
                                                name="인증번호"
                                                :rules="{required:true, min:5}"
                                                v-slot="validationContext">
                                            <BInputGroup  class="mb-3" >
                                                <BInputGroupPrepend is-text>
                                                    <BIconKeyFill/>
                                                </BInputGroupPrepend>
                                                <BFormInput

                                                        v-model="order.auth_no"
                                                        type="number"
                                                        placeholder="이메일로 전송된 인증번호 입력"
                                                        :state="getValidationState(validationContext)"
                                                        aria-describedby="pwd-feedback"
                                                        :disabled="!isEmailChecked"
                                                />
                                                <BFormInvalidFeedback id="pwd-feedback" class="text-left">
                                                    {{ validationContext.errors[0] }}
                                                </BFormInvalidFeedback>
                                                <BFormValidFeedback>주문등록시 인증번호를 확인합니다.</BFormValidFeedback>
                                            </BInputGroup>
                                        </validation-provider>



                                        <BInputGroup  class="mb-3">
                                            <BInputGroupAppend is-text>단 가</BInputGroupAppend>
                                            <BFormInput class="text-right" v-model="unitPrice" readonly/>
                                            <BInputGroupAppend is-text>주문 수량</BInputGroupAppend>
                                            <BFormInput v-model="order.qty"
                                                        class="text-right font-weight-bolder border-primary"
                                                        placeholder="주문수량"
                                                        @input="calcPrice"
                                                        min="1"
                                                        max="10"
                                                        type="number"
                                                        required/>
                                            <BInputGroupAppend is-text>총 금액</BInputGroupAppend>
                                            <BFormInput class="text-right font-weight-bold bg-warning" v-model="totalPrice" readonly/>
                                        </BInputGroup>


                                        <BInputGroup  class="mb-3">
                                            <BInputGroupPrepend is-text>
                                                <BIconPersonCircle/>
                                            </BInputGroupPrepend>
                                            <BFormInput
                                                    v-model="order.name"
                                                    placeholder="이름"
                                                    autocomplete="name"
                                                    required
                                            />
                                        </BInputGroup>

                                        <BInputGroup  class="mb-3">
                                            <BInputGroupPrepend is-text>
                                                <BIconTelephone/>
                                            </BInputGroupPrepend>
                                            <BFormInput
                                                    v-model="order.phone_no"
                                                    placeholder="휴대폰번호 또는 전화번호"
                                                    autocomplete="phone_no"
                                                    required
                                            />
                                        </BInputGroup>
                                        <BInputGroup  class="mb-3">
                                            <BInputGroupPrepend is-text>
                                                메모
                                            </BInputGroupPrepend>
                                            <BFormInput
                                                    v-model="order.description"
                                                    placeholder="기타 요청사항 등 메모 입력"
                                                    autocomplete="description"
                                            />
                                        </BInputGroup>

                                        <BInputGroup v-if="false"  class="mb-1">
                                            <BInputGroupPrepend is-text>
                                                <BIconMapFill/>
                                            </BInputGroupPrepend>
                                            <BFormInput
                                                    v-model="order.description"
                                                    @click="addressModalShow"
                                                    placeholder="배송지 주소 입력"
                                                    autocomplete="address"
                                                    required
                                            />
                                            <BInputGroupAppend>
                                                <BButton
                                                        variant="primary"
                                                        v-b-modal.addressModal>주소검색</BButton>
                                            </BInputGroupAppend>
                                        </BInputGroup>

                                        <BInputGroup v-if="false"  class="mb-3">
                                            <BInputGroupPrepend is-text>
                                                상세주소
                                            </BInputGroupPrepend>
                                            <BFormInput
                                                    id="addrDetail"
                                                    ref="addrDetail"
                                                    v-model="order.addr_detail"
                                                    placeholder="배송지 상세주소 입력"
                                                    autocomplete="address"
                                                    required
                                            />
                                        </BInputGroup>

                                        <BInputGroup  class="mb-2">
                                            <BInputGroupPrepend is-text>
                                                <BIconUiChecksGrid/>
                                            </BInputGroupPrepend>
                                            <BFormSelect required
                                                         v-model="order.tax_stat"
                                                         :options="taxStatOpts"/>
                                        </BInputGroup>

                                        <BCollapse id="compInfo" v-model="order.tax_stat==='01'">
                                            <BCard class="m-0"
                                                   border-variant="primary"
                                                   header="primary"
                                                   header-bg-variant="primary"
                                                   header-text-variant="white" >
                                                <div slot="header">
                                                    <strong>사업자 등록 정보</strong>
                                                    <div class="card-header-actions">
                                                        <small>계산서 발행을 위해 정확하게 기재해 주시기 바랍니다.</small>
                                                    </div>
                                                </div>

                                                <BInputGroup size="sm" class="mb-2">
                                                    <BInputGroupPrepend is-text>
                                                        <BIconCreditCard2Front/>
                                                    </BInputGroupPrepend>
                                                    <BFormInput
                                                            v-model="order.comp_name"
                                                            type="text"
                                                            placeholder="회사명/상호"
                                                            autocomplete="comp_name"
                                                            :required="order.tax_stat==='01'"
                                                    />
                                                </BInputGroup>

                                                <BInputGroup size="sm" class="mb-2">
                                                    <BInputGroupPrepend is-text>
                                                        <BIconCreditCard2Front/>
                                                    </BInputGroupPrepend>
                                                    <BFormInput
                                                            v-model="order.pr_name"
                                                            type="text"
                                                            placeholder="대표자명"
                                                            autocomplete="pr_name"
                                                            :required="order.tax_stat==='01'"
                                                    />
                                                </BInputGroup>

                                                <BInputGroup size="sm" class="mb-2">
                                                    <BInputGroupPrepend is-text>
                                                        <BIconCardList/>
                                                    </BInputGroupPrepend>
                                                    <BFormInput
                                                            v-model="order.biz_kind"
                                                            type="text"
                                                            placeholder="업태/업종 입력 예) 건설업/실내건축공사업"
                                                            autocomplete="biz_kind"
                                                            :required="order.tax_stat==='01'"
                                                    />
                                                </BInputGroup>


                                                <BInputGroup  class="mb-2">
                                                    <BInputGroupPrepend is-text>
                                                        <BIconBuilding/>
                                                    </BInputGroupPrepend>
                                                    <BFormInput
                                                            v-model="order.comp_no"
                                                            type="text"
                                                            placeholder="사업자 번호"
                                                            :state="compnoState"
                                                            autocomplete="comp_no"
                                                            :required="order.tax_stat==='01'"
                                                    />
                                                </BInputGroup>


                                                <BInputGroup size="sm" class="mb-2">
                                                    <BInputGroupPrepend is-text>
                                                        <BIconMap/>
                                                    </BInputGroupPrepend>
                                                    <BFormInput
                                                            v-model="order.address"
                                                            placeholder="사업장 소재지"
                                                            autocomplete="address"
                                                            :required="order.tax_stat==='01'"
                                                    />
                                                </BInputGroup>
                                            </BCard>
                                        </BCollapse>



                                        <BRow class="mt-3" v-if="false">
                                            <BCol sm="8" class="text-right">
                                                <BFormCheckbox v-model="order.agreement" value="Y" unchecked-value="N">
                                                    약관에 모두 동의 합니다.
                                                </BFormCheckbox>
                                            </BCol>
                                            <BCol class="text-right">
                                                <BButton size="sm" v-b-modal.termsModal variant="info">약관보기</BButton>
                                            </BCol>
                                        </BRow>



                                        <BRow class="mt-5">
                                            <BCol class="text-center">
                                                <BButtonGroup>
                                                    <BButton type="submit" variant="success" class="mr-2">
                                                        <BSpinner small v-show="isSubmiting"></BSpinner>
                                                        주문등록
                                                    </BButton>
                                                    <BButton type="button" variant="danger" @click="resetForm"> reset</BButton>
                                                </BButtonGroup>
                                            </BCol>
                                        </BRow>

                                    </BForm>
                                </ValidationObserver>
                            </BCol>
                        </BRow>
                    </BCard>

                </BCol>
            </BRow>


        </BContainer>

        <b-modal id="termsModal" size="xl" scrollable title="이용약관">
            <BFormTextarea
                    v-model="termsText"
                    rows="100"
                    max-rows="100"
                    disabled
            ></BFormTextarea>
        </b-modal>

        <BModal id="addressModal" title="우편번호 검색" centered return-focus="addrDetail">
            <DaumPostcode :on-complete="handleAddress"/>
        </BModal>

    </div>
</template>

<script>
    import {
        apiCall,
        cloneVar,
        alertError,
        alertWarn,
        alertConfirm,
        checkBisNo,
        commify
    } from '../../../common/utils';
    import terms from "./_terms";
    import DaumPostcode from 'vuejs-daum-postcode';
    import moment from "moment";

    const _order = {
        prd_code     : null,
        dept_code    : null,
        unit_price   : null,
        qty          : 1,
        warranty_mon : null,
        price        : null,
        pay_amt      : null,
        pay_type     : null,
        cu_seq       : null,
        cu_id        : null,
        ord_dt       : null,
        email        : null,
        name         : null,
        phone_no     : null,
        comp_no      : null,
        comp_name    : null,
        pr_name      : null,
        address      : null,
        zip_code     : null,
        appr_yn      : null,
        appr_dt      : null,
        send_stat    : null,
        send_dt      : null,
        pay_stat     : null,
        pay_dt       : null,
        tax_stat     : null,
        tax_dt       : null,
        tax_seq      : null,
        biz_kind     : null,
        cncl_yn      : null,
        cncl_dt      : null,
        cncl_desc    : null,
        description  : '',
        reg_dt       : null,
        upd_dt       : null,
        reg_id       : null,
        upd_id       : null,
        agreement    : null,
        auth_no      : null,
        addr_detail  : '',
        auth_seq     : null
    };


    export default {
        name: 'OrderForm',
        components: {
            DaumPostcode
        },
        props: ['prdCode'],
        data () {
            return {
                // prdCode: '2106-0002',
                order: cloneVar(_order),
                isEmailChecked: false,
                termsText: terms[0].text,
                emailValidCheckText: '이메일 형식입니다 (인증번호 전송버튼을 클릭하세요)',
                taxStatOpts: [
                    { value: null, text: '개인/사업자 선택' },
                    { value: '03', text: '개인' },
                    { value: '01', text: '사업자' },
                ],
                isSubmiting: false,
                isEmailSending:false,
                prdInfo: null,
                imgSrc: null,
                unitPrice: null,
                totalPrice: null,
                orderEnabled: false,
                prdCodeOpts: [
                    { text:'1개(1+1)', value:'2106-0002' },
                    { text:'1개', value:'2106-0001' },
                ]
            }
        },
        async created(){

            // console.log("-------- created: OrderForm ----- prdCode : " + this.prdCode );

            try{
                await this.getPrdInfo();

                this.unitPrice = commify(this.prdInfo.unit_price) + ' 원';
                this.totalPrice = commify(this.prdInfo.unit_price*this.order.qty) + ' 원';
                const pubDay = moment(this.prdInfo.pub_dt).format("YYYY-MM-DD");
                const endDay = moment(this.prdInfo.end_dt).format("YYYY-MM-DD");
                const toDay = moment().format("YYYY-MM-DD");
                // console.log( "pubDay, endDay, toDay ----- ", pubDay, endDay, toDay );
                if( toDay < pubDay || toDay > endDay)
                    this.orderEnabled = false;
                else
                    this.orderEnabled = true;

            }catch(e){

            }
        },
        beforeMount() {

        },
        mounted(){
            console.log("-------- mounted: OrderForm ----- prdCode : " + this.prdCode );

        },

        computed: {
            compnoState: function(){
                return checkBisNo(this.order.comp_no);
            }
        },
        methods: {
            addressModalShow(){
                this.$bvModal.show("addressModal");
            },
            calcPrice(){
                this.order.price = this.prdInfo.unit_price * this.order.qty;
                this.totalPrice = commify(this.order.price) + ' 원';
            },

            async selectPrdCode(){
                await this.getPrdInfo();

                try{
                    if( this.prdCode==='2106-0002'){
                        this.order.qty = 2;
                    }else{
                        this.order.qty = 1;
                    }
                    this.order.price = this.prdInfo.unit_price * this.order.qty;
                    this.unitPrice = this.prdInfo.unit_price;
                    this.totalPrice = commify(this.order.price) + ' 원';
                }catch(err){
                    console.log(err);
                }

            },

            handleAddress(data) {
                console.log( "handleAddress -------------> ", data );
                let fullAddress = data.address;
                let extraAddress = '';
                if (data.addressType === 'R') {
                    if (data.bname !== '') {
                        extraAddress += data.bname
                    }
                    if (data.buildingName !== '') {
                        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName)
                    }
                    fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '')
                }
                console.log(fullAddress);

                this.order.description = fullAddress;
                this.order.zip_code = data.zonecode;
                this.$bvModal.hide("addressModal");
                this.$nextTick(
                    ()=>{
                        this.$refs.addrDetail.focus();
                    }
                );

            },

            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },

            async getPrdInfo(){
                console.log( "getPrdInfo ---- "+this.prdCode );

                try{
                    const r = await apiCall('get', `/api/order/prd-info/${this.prdCode}`);
                    if(r.result.length){
                        this.prdInfo = r.result[0];
                        this.imgSrc = $baseURL + "/images/" + this.prdInfo.file_nm;
                        //console.log( this.prdInfo );
                        //console.log( this.imgSrc );
                    }else{
                        await alertWarn( this.$bvModal, `잘못된 접근입니다`, 'Not Found' );
                        return;
                    }
                }catch(err){
                    console.log(err);
                }
            },

            async onSubmit(evt){
                console.log( "-------onSubmit---------" );

                try{

                    if( this.order.tax_stat==='01' && (this.order.comp_no) ){
                        this.order.comp_no  = this.order.comp_no.replace(/[^0-9]/g,"");

                        if(!checkBisNo( this.order.comp_no )){
                            await alertWarn( this.$bvModal, `사업자번호가 검증 실패`, '사업자번호 체크' );
                            return;
                        }
                    }

                    if(!this.isEmailChecked){
                        await alertWarn(this.$bvModal, "인증번호 전송후 입력하세요", "인증번호 전송");
                        return;
                    }

                    /*
                    if( this.order.agreement!=="Y" ){
                        await alertWarn(this.$bvModal, "약관 동의가 필요합니다", "약관 동의 확인");
                        return;
                    }*/

                    this.isSubmiting = true;
                    this.order.prd_code = this.prdCode;

                    const r = await apiCall('post', `/api/order/confirm`, this.order);
                    //console.log( "onSubmit ----------------> result: ", r );
                    if(r.code===200){
                        // await alertSuccess(this.$bvModal, '접수 되었습니다. 주문 내용은 이메일을 확인하시기 바랍니다.');
                        const orderToken = r.result.orderToken;
                        await this.$router.push( {name:"OrderDetail", params: {orderToken: orderToken} } );

                    }else{
                        await alertError(this.$bvModal, `[ERROR] ${r.message}`);
                    }
                    this.isSubmiting = false;

                }catch(err){
                    console.log(err);
                }finally{
                    this.isSubmiting = false;
                }
            },
            resetForm() {
                this.order = cloneVar(_order);
                this.isEmailChecked = false;

                this.$nextTick(() => {
                    this.$refs.observer.reset();
                });
            },

            async sendAuthEmail(){
                try{
                    const ret = await alertConfirm(this.$bvModal, `${this.order.email}로 인증번호를 전송합니다.`, '이메일 전송' );
                    if(!ret){
                        return;
                    }
                    //console.log("sendAuthEmail ------------- order:", this.order);

                    this.isEmailSending = true;
                    const r = await apiCall('post', '/api/order/send-auth-email', this.order);
                    //console.log( "sendAuthEmail", r );
                    if( r.code===200 ) {
                        if (r.result) {
                            this.isEmailChecked = true;
                            this.emailValidCheckText = '이메일을 전송하였습니다. 인증번호를 입력하세요';
                            this.order.auth_seq = r.result.auth_seq;
                        }else {
                            this.isEmailChecked = false;
                            await alertError(this.$bvModal, "이메일을 확인하신후 재인증 하세요");
                            return;
                        }
                    }else{
                        await alertError(this.$bvModal, "이메일 전송실패", r.code);
                    }
                }catch(err){
                    console.log(err);
                }finally{
                    this.isEmailSending = false;
                }

            }
        },

    }
</script>
